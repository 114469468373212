<template>
  <b-card title="Filters" class="mb-0">
    <b-form @submit.prevent>
      <b-row>

        <!-- Period -->
        <b-col cols="12" md="2">
          <b-form-group :label="$t('apps.reportLedger.singular.period')" label-for="period">
            <v-select class="select-size-sm" v-model="form.periodeType" :options="LOV.periodeType" :reduce="field => field.value"
              @input="handlePeriod" />
          </b-form-group>
        </b-col>
        <!-- Period -->

        <!-- Date Start -->
        <b-col cols="12" md="2" v-show="form.showDaily">
          <b-form-group :label="$t('apps.reportLedger.singular.dateStart')" label-for="date-start">
            <flat-pickr id="date-start" :config="dateFormat" v-model="form.dateStart"
              class="form-control form-control-sm" />
          </b-form-group>
        </b-col>

        <!-- Date End -->
        <b-col cols="12" md="2" v-show="form.showDaily">
          <b-form-group :label="$t('apps.reportLedger.singular.dateEnd')" label-for="date-start">
            <flat-pickr id="date-end" :config="dateFormat" v-model="form.dateEnd"
              class="form-control form-control-sm" />
          </b-form-group>
        </b-col>

        <!-- Year -->
        <b-col cols="12" md="4" v-show="form.showYear">
          <b-form-group :label="$t('globalSingular.year')" label-for="year">
            <validation-provider #default="{ errors }" name="year" rules="required">
              <v-select inputId="year" class="select-size-sm" v-model="form.year" :options="LOV.year"
                :state="errors.length" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- /year  -->

        <!-- month  -->
        <b-col cols="12" md="4" v-show="form.showMonth">
          <b-form-group :label="$t('apps.reportBudget.singular.month')" label-for="month">
            <validation-provider #default="{ errors }" name="month" rules="required">
              <v-select inputId="month" class="select-size-sm" v-model="form.month" :options="LOV.month"
                :reduce="field => field.value" :state="errors.length" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Button Filter -->
        <b-col cols="12" md="2">
          <b-button block variant="primary" @click="generateEndpointAPI" class="mt-2 pd-1">
            {{ $t('globalActions.find') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { format, startOfMonth, endOfMonth, parse } from 'date-fns'
import moment from 'moment'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    baseReportUrl: {
      type: String,
      required: true
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
    flatPickr
  },
  setup(props, { emit }) {

    const form = ref({
      dateStart: null,
      dateEnd: null,
      year: '2022',
      periodeType: 'daily',
      month: 1,
      showDaily: false,
      showMonth: false,
      showYear: false
    })

    const periodReportText = ref()
    const periodeTypeText = ref()
    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const generatedUrl = (periodeType = form.value.periodeType, dateStart = form.value.dateStart, dateEnd = form.value.dateEnd, year = form.value.year, month = form.value.month) => {
      const params = [
        `periodeType=${periodeType}`,
        `dateStart=${dateStart}`,
        `dateEnd=${dateEnd}`,
        `year=${year ?? ''}`,
        `month=${month ?? ''}`
      ]
      return `${props.baseReportUrl}?${ params.join('&') }`
    }

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()

      if (form.value.periodeType === 'daily') {
        form.value.showDaily = true
        form.value.showMonth = false
        form.value.showYear = false
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.periodeType === 'week') {
        form.value.showDaily = false
        form.value.showMonth = true
        form.value.showYear = true
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.periodeType === 'month') {
        form.value.showDaily = false
        form.value.showMonth = false
        form.value.showYear = true
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      }
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      let text
      if(form.value.periodeType === 'daily'){
        text = `${formatStartDate} to ${formatEndDate}`
      }else if(form.value.periodeType === 'week'){
        text = `${moment(form.value.month, "DD/MM/YYYY").format("MMMM")}  ${form.value.year}`
      }else if(form.value.periodeType === 'month'){
        text = `${form.value.year}`
      }
      periodReportText.value = text
      return text
    }

    const periodeType = () => {
      const text = form.value.periodeType
      periodeTypeText.value = text
      return text
    }

    const LOV = ref({
      periodeType: [
        { value: 'daily', label: 'Daily' },
        { value: 'week', label: 'Weekly' },
        { value: 'month', label: 'Monthly' }
      ],
      year: [
        2021,
        2022,
        2023,
        2024
      ],
      month: [
        {
          label: 'January',
          value: 1
        },
        {
          label: 'February',
          value: 2
        },
        {
          label: 'March',
          value: 3
        },
        {
          label: 'April',
          value: 4
        },
        {
          label: 'May',
          value: 5
        },
        {
          label: 'June',
          value: 6
        },
        {
          label: 'July',
          value: 7
        },
        {
          label: 'Agust',
          value: 8
        },
        {
          label: 'September',
          value: 9
        },
        {
          label: 'October',
          value: 10
        },
        {
          label: 'November',
          value: 11
        },
        {
          label: 'December',
          value: 12
        }
      ]
    })

    const generateEndpointAPI = () => {
      emit('clicked-filter', {
        generatedUrl: generatedUrl(),
        periodText: updatePeriodText(),
        officeText: '',
        periodeType: periodeType()
      })
    }

    onMounted(async () => {
      handlePeriod()
      updatePeriodText()
      periodeType()
      generateEndpointAPI()
    })

    return {
      form,
      LOV,
      generateEndpointAPI,
      handlePeriod,
      dateFormat,
      updatePeriodText,
      periodeType
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>
